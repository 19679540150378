// CSS Imports
import '/src/css/style.css';

// NPM Imports
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';

// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// init Swiper:
const swiperProducts = new Swiper('.swiper-products', {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
  centeredSlides: false,
  spaceBetween: 20,
  grabCursor: true,
  
  
  breakpoints: {
    // when window width is >= 1024px
    1024: {
      slidesPerView: 1.2,
    }
  },
  
  keyboard: {
    enabled: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction"
  },
  
});

const swiperHome = new Swiper('.swiper-home', {
  // configure Swiper to use modules
  modules: [Navigation],
  centeredSlides: false,
  spaceBetween: 0,
  grabCursor: true,
  
  keyboard: {
    enabled: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

window.Alpine = Alpine;
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// if (import.meta.hot) {
//   import.meta.hot.accept(() => {
//     console.log("HMR");
//   });
// }
